import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const FullScreenContainer = ({ className, children }) => {
  return (
    <Wrapper
      className={
        'full-screen-container px-6 container mx-auto w-full block ' +
        (!!className ? className : '')
      }
    >
      {children}
    </Wrapper>
  );
};

export default FullScreenContainer;
